<template>
    <div class="page-wrap">
        <div class="ma-auto">
            <div class="d-flex align-center justify-center mb-4">
                <v-avatar tile size="120" class="mr-4">
                    <img src="@/assets/images/svg/warning.svg" alt="" />
                </v-avatar>
                <div class="error-title">
                    <h1 class="font-weight-bold mb-0">{{ $route.params.code }}</h1>
                    <div class="text-18 font-weight-bold">{{ $route.params.message }}</div>
                </div>
            </div>
            <div class="d-flex justify-center flex-wrap">
                <v-btn small class="ma-2" outlined color="primary" @click="loginAgain">Re-Login</v-btn>
                <!-- <v-btn small class="ma-2" outlined color="danger"
                >Report the problem</v-btn
            > -->
            </div>
        </div>
    </div>
</template>
<script>
import * as authenticationService from '../../auth/authenticate'

export default {
    name: 'Error',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Error'
    },
    methods: {
        loginAgain() {
            authenticationService.getMsalPublicClient().handleRedirectPromise().then((response) => {
                authenticationService.LoginAndGetToken(response).then((tokenResponse) => { 
                    this.$router.push({
                        name: 'blank'
                    });
                })
            })
        }
    },
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}

.error-title {
    h1 {
        font-size: 5rem;
    }
}
</style>
